import { RefresherEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useToast } from "@src/appV2/lib";
import { ShiftLoader } from "@src/lib/deprecatedCode";
import { useCallback, useEffect, useState } from "react";

import { FCMRequestData } from "./interfaces";
import { PendingSentHomeRequestsCard } from "./pendingSentHomeRequestsCard";
import { fetchSentHomeRequests } from "../api/shift";

export function PendingSentHomeRequestList() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fcmRequestsData, setFcmRequestsData] = useState<FCMRequestData[]>([]);
  const { showErrorToast } = useToast();

  const updateFcmRequests = useCallback(
    async (event?: CustomEvent<RefresherEventDetail>) => {
      try {
        // If event is not provided, it's a page load.
        setIsLoading(!event);
        const fcmRequestsResponse = await fetchSentHomeRequests();
        setFcmRequestsData(fcmRequestsResponse);
      } catch {
        showErrorToast("Could not load pending sent home requests, please refresh the page again!");
      } finally {
        setIsLoading(false);
      }
    },
    [showErrorToast]
  );

  const doRefresh = useCallback(
    async (event: CustomEvent<RefresherEventDetail>) => {
      await updateFcmRequests(event);
      event.detail.complete();
    },
    [updateFcmRequests]
  );

  useEffect(() => {
    updateFcmRequests();
  }, [updateFcmRequests]);

  return (
    <IonPage>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/myShifts" mode="ios" />
          </IonButtons>
          <IonTitle className="ion-text-center">Pending Sent Home Requests</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} data-testid="refresher">
          <IonRefresherContent />
        </IonRefresher>
        {isLoading ? (
          <ShiftLoader loading={isLoading} count={1} />
        ) : (
          <>
            {fcmRequestsData && fcmRequestsData.length > 0 ? (
              fcmRequestsData.map((fcmRequest: FCMRequestData) => (
                <IonCard
                  key={fcmRequest.shift._id}
                  mode="ios"
                  button
                  className="ion-padding no-text-transform"
                  data-testid={fcmRequest.fcm._id}
                >
                  <PendingSentHomeRequestsCard fcmRequest={fcmRequest} />
                </IonCard>
              ))
            ) : (
              <div className="ion-text-center ion-margin-top">
                <h4 style={{ fontWeight: "400" }}>No pending sent home requests</h4>
              </div>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
}
